import DashboardIcon from "../../../assets/images/icons/dashboard";
import MasterIcon from "../../../assets/images/icons/master";
import InventoryIcon from "../../../assets/images/icons/inventory";
// import OrderIcon from "../../../assets/images/icons/order";
import ReportIcon from "../../../assets/images/icons/report";
import SettingIcon from "../../../assets/images/icons/setting";
import SubmenuIcon from "../../../assets/images/icons/submenu";
// import LogoutIcon from "../../../assets/images/icons/logout";
import RevenueIcon from "../../../assets/images/icons/revenue";
interface SubMenuConfig {
    name: string;
    path: string;
    icon?: React.ReactNode
}

interface SideBarConfig {
    name: string;
    path: string;
    icon: React.ReactNode
    subMenu?: SubMenuConfig[]
}

export const sidebarConfig: SideBarConfig[] = [
    {
        name: 'Dashbaord',
        path: '/',
        icon: <DashboardIcon/>
    },
    {
        name: 'Master',
        path: '',
        icon: <MasterIcon/>,
        subMenu: [
            {
                name: 'Role',
                path: '/role',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Module',
                path: '/module',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Company',
                path: '/company',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Rights',
                path: '/right',
                icon:<SubmenuIcon/>},
            {
                name: 'Setting',
                path: '/setting',
                icon:<SubmenuIcon/>
            },
            {
                name: 'User',
                path: '/user',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Seller',
                path: '/seller',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Master Details',
                path: '/master-details',
                icon:<SubmenuIcon/>
            },
            {
                name: 'FAQ',
                path: '/faq',
                icon:<SubmenuIcon/>
            }
        ]
    },
    {
        name: 'Product Management',
        path: '',
        icon: <MasterIcon/>,
        subMenu: [
            {
                name: 'Category',
                path: '/category',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Sub Category',
                path: '/sub-category',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Product',
                path: '/product',
                icon:<SubmenuIcon/>
            },
           
        ]
    },
    {
        name: 'Stock Management',
        path: '',
        icon: <MasterIcon/>,
        subMenu: [
            {
                name: 'Purchase Material Details',
                path: '/purchase-material-details',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Material',
                path: '/material',
                icon:<SubmenuIcon/>
            },
            // {
            //     name: 'Material Stock History',
            //     path: '/material-stock-history',
            //     icon:<SubmenuIcon/>
            // },
            {
                name: 'Stock Details',
                path: '/stock-details',
                icon:<SubmenuIcon/>
            },
            // {
            //     name: 'Stock History',
            //     path: '/stock-history',
            //     icon:<SubmenuIcon/>
            // },
        ]
    },
    {
        name: 'Invoice',
        path: '',
        icon: <MasterIcon/>,
        subMenu: [
            {
                name: 'Proforma invoice',
                path: '/proforma-invoice',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Invoice',
                path: '/invoice-details',
                icon:<SubmenuIcon/>
            },
            {
                name: 'Purchase Order',
                path: '/purchase-invoice',
                icon:<SubmenuIcon/>
            },
        ]
    },
    // {
    //     name: 'Report',
    //     path: '',
    //     icon: <MasterIcon/>,
    //     subMenu: [
    //         {
    //             name: 'Performa Invoice',
    //             path: '/report/performa-invoice',
    //             icon: <SubmenuIcon/>
    //         },
    //         {
    //             name: 'Invoice',
    //             path: '/report/invoice',
    //             icon: <SubmenuIcon/>
    //         },
    //         {
    //             name: 'Stock',
    //             path: '/report/stock',
    //             icon: <SubmenuIcon/>
    //         },
    //         {
    //             name: 'Outstanding Payment',
    //             path: '/report/outstanding-payment',
    //             icon: <SubmenuIcon/>
    //         }
    //     ]
    // },
    {
        name: 'Report',
        path: '/report',
        icon: <ReportIcon/>,
    },
    {
        name: 'Payment',
        path: '/payment',
        icon: <RevenueIcon/>
    },
    {
        name: 'Customer',
        path: '/customer',
        icon:<InventoryIcon/>
    },
    // {
    //     name: 'Inventory',
    //     path: '/inventory',
    //     icon: <InventoryIcon/>
    // },
    // {
    //     name: 'Order',
    //     path: '/order',
    //     icon: <OrderIcon/>
    // },
    // {
    //     name: 'Report',
    //     path: '/report',
    //     icon: <ReportIcon/>
    // },
    {
        name: 'Setting',
        path: '/setting',
        icon: <SettingIcon/>
    },
    // {
    //     name: 'Logout',
    //     path: '/logout',
    //     icon: <LogoutIcon/>
    // },
];


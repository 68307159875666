import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginUser } from "../../pages/login/hooks/loginhook";

export interface AppSliceState {
  loginUser: LoginUser | null;
  isAdmin: boolean;
  moduleList: any[];
  menuOpen: boolean;
  loading: boolean;
  currentPageAccess: {
    add: boolean;
    edit: boolean;
    delete: boolean;
  };
}

const initialState: AppSliceState = {
  loginUser: null,
  isAdmin: false,
  moduleList: [],
  menuOpen: true,
  loading: false,
  currentPageAccess: {
    add: false,
    edit: false,
    delete: false,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setLoginUser(state, action: PayloadAction<LoginUser>) {
      state.loginUser = action.payload;
      state.isAdmin = ['admin', 'super admin'].includes(action.payload?.roleId?.name.toLowerCase() || '');
    },
    getCurrentPageAccess(state, action: PayloadAction<{ path?: string }>) {
      const path = action.payload.path || window.location.pathname;
      const currentModule = state.loginUser?.accessModules.find(
        (module) => module.moduleId.path === path
      );
      if (currentModule) {
        state.currentPageAccess.add = currentModule.add;
        state.currentPageAccess.edit = currentModule.edit;
        state.currentPageAccess.delete = currentModule.delete;
      } else {
        state.currentPageAccess = {
          add: false,
          edit: false,
          delete: false,
        };
      }
    }
  },
});

export const appSliceActions = appSlice.actions;
export default appSlice.reducer;
